
import { defineComponent, ref, onMounted, watch, Ref } from 'vue';
import { useRoute } from 'vue-router';
import axios, { AxiosError } from 'axios';
import useAlert from "@/composables/Alert";
import router from '@/router';
import {
  CampHeader,
  CampFormHeader,
  CampFormRackSubmitBtn,
  CampModalMediaFromURL,
} from '@/components';
import { useLoaderStore } from "@/store/LoaderStore";
import { TOptions, deleteMediaFromClient, uploadMediaFromClient } from '@/services/AzureBlobService';
import moment from 'moment';
import { getFileContentType } from '@/composables/DataValidation';

interface IAchievementData {
  id: number,
  title: string,
  description_on: string | null,
  description_off: string | null,
  url_icon:  string | null,
  target: string | number | null,
  // active: boolean | number,
}

interface IAchievementResponse {
  data: {
    error: boolean,
    message: string,
    data: IAchievementData
    errorCode: string
  },
}

interface IFileInput {
  file: File | null;
  name: string | null;
  type: string | null;
  size: number | null;
  href: string | null;
}

const INIT_FILE_INPUT = {
  file: null,
  name: null,
  type: null,
  size: null,
  href: null,
}
export default defineComponent({
  name: "AchievementPut",
  components: {
    CampHeader,
    CampFormHeader,
    CampFormRackSubmitBtn,
    CampModalMediaFromURL,
  },
  setup() {
    /** Variables */
    const loaderStore = useLoaderStore();
    const route = useRoute();
    const { id } = route.params;
    const { showTimeAlert } = useAlert()
    const achievement = ref<IAchievementData | null>(null)
    const isRequiredField = ref(false)
    const iconFile = ref<IFileInput>(JSON.parse(JSON.stringify(INIT_FILE_INPUT)))
    const iconModal = ref<{
      toggle: boolean,
      src: string | null,
      contentType?: string,
      header: string,
    }>({
      toggle: false,
      src: null,
      header: "Mídia da Conquista",
    })

    /** Functions */
    async function handleGetAchievement() {
      try {
        const result: IAchievementResponse = await axios.get(`/api/getAchievementView/${id}`)
        const { data } = result.data
        achievement.value = data
        // achievement.value.active = !!achievement.value.active
        if(achievement.value.url_icon) 
          await handleUrlIcon(iconFile, achievement.value.url_icon)
      } catch (error) {
        if(error instanceof AxiosError)
          showTimeAlert(error.response?.data.message, "error")
        else
          showTimeAlert("Algo deu errado!", "error")
      } finally {
        loaderStore.close()
      }
    }

    async function handleUrlIcon(iconFile: Ref<IFileInput>, url_icon: string) {
      const url = new URL(url_icon)
      const index = url.pathname.lastIndexOf("/") + 1
      iconFile.value.href = url.href
      iconFile.value.name = url.pathname.substring(index)
      iconFile.value.file = null
      iconFile.value.size = null
      iconFile.value.type = await getFileContentType(url.href)
    }

    async function handleGetIconStd(evt: Event) {
      const files: FileList | null = (evt.target as HTMLInputElement).files;
      if(!files) {
        return {
          error: "Não foi possível obter o arquivo",
          file: null
        }
      }

      const fileListArray: File[] = Array.from(files);

      const file = fileListArray[0]

      const exp = 6 /** MB = 1e+6 */
      if(file.size > 10**exp) {
        return {
          error: `Tamanho do arquivo ${(file.size * 10**-exp).toFixed(2)} MB. Deve ser inferior à ${file.size * 10**exp/file.size * 10**-exp} MB.`,
          file: null,
          url: null
        }
      }

      if(!file.type.startsWith('image/')) {
        return {
          error: `Deve ser um arquivo do tipo imagem`,
          file: null,
          url: null
        }
      }

      return {
        error: null,
        file: file,
        url: URL.createObjectURL(file),
      }
    }

    async function handleGetIcon(evt: Event) {
      const data = await handleGetIconStd(evt)
      if(data.error || !data.file)
        return showTimeAlert(data.error, "error")
      iconFile.value.file = data.file
      iconFile.value.href = data.url
      iconFile.value.name = data.file.name
      iconFile.value.size = data.file.size
      iconFile.value.type = data.file.type
      handleModalIcon()
    }

    function handleDelIcon() {
      iconFile.value = JSON.parse(JSON.stringify(INIT_FILE_INPUT))
    }

    async function handleUploadIconFile(iconFile:Ref<IFileInput>, name: string, urlBucketToRemove: string | null) {
      if(iconFile.value.file && iconFile.value.type && iconFile.value.name && achievement.value?.id) {
        const options: TOptions = {
          blobHTTPHeaders: {
            blobContentType: iconFile.value.type
          }
        }
        const fileExtension = (iconFile.value.name).split(".")[1]
        const fileName = `achievements/icons/${achievement.value.id}/${moment().valueOf()}-${name}.${fileExtension}`
        try {
          const { request } = (await uploadMediaFromClient(iconFile.value.file, fileName, options))._response
          iconFile.value.href = request.url
        } catch (error) {
          loaderStore.close()
          showTimeAlert('Erro ao atualizar media, tente mais tarde!', 'error')
          return true
        }
      }
      try {
        if(urlBucketToRemove) {
          const url = new URL(urlBucketToRemove)
          const blobNameToRemove = url.pathname.replace("/pontuei/", "")
          if(!iconFile.value.name || iconFile.value.file)
            await deleteMediaFromClient(blobNameToRemove)
        }
      } catch (error) {
        console.error(error)
        loaderStore.close()
        showTimeAlert('Erro ao atualizar media, tente mais tarde!', 'error')
        return true
      }

      return false
    }

    function handleModalIcon() {
      iconModal.value.header = "Mídia do ícone"
      iconModal.value.src = iconFile.value.href
      iconModal.value.contentType = iconFile.value.type ?? undefined
      if(iconModal.value.src && iconModal.value.contentType && iconFile.value.name) {
        iconModal.value.header = `Mídia: ${iconFile.value.name}`
        iconModal.value.toggle = !iconModal.value.toggle
      }
    }

    async function onSubmitForm() {
      try {
        if(!achievement.value)
          return
        loaderStore.open()
        isRequiredField.value = false
      
        const errorIiconFile = await handleUploadIconFile(iconFile, '', achievement.value.url_icon)
        if(errorIiconFile)
          return

        achievement.value.url_icon = iconFile.value.href

        if(!achievement.value.title)
          return isRequiredField.value = true

        const result: IAchievementResponse = await axios.put(`/api/putAchievement/${id}`, { ...achievement.value })
        showTimeAlert("Atualizado com sucesso!")
        return router.push("/conquistas-auxiliares")
      } catch (error) {
        if(error instanceof AxiosError)
          showTimeAlert(error.response?.data.message, "error")
        else
          showTimeAlert("Algo deu errado!", "error")
      } finally {
        loaderStore.close()
      }
    }

    /** Life Cycles */
    onMounted(() => handleGetAchievement())

    watch(
      () => { achievement.value, iconFile.value.file },
      () => isRequiredField.value = false,
      { deep: true }
    )

    return {
      achievement,
      iconFile,
      isRequiredField,
      handleGetIcon,
      handleDelIcon,
      iconModal,
      handleModalIcon,
      onSubmitForm,
    }
  }
})
